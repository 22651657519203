<style lang="scss">

</style>
<!--eslint-disable-->
<template>

  <section class="text-center">
    <h3>Are you sure?</h3>
    <h4 class="font-weight-bolder my-2">
      This will deallocate this device from this Owner.
    </h4>

    <p>Please make sure you have the device in your hands before proceeding</p>

    <section class="my-2">
      <b-form-checkbox
        id="checkbox-1"
        v-model="accepted"
        name="checkbox-1"
        value="accepted"
        unchecked-value="not_accepted"
      >
        <span style="text-align: left">
          I understand that deallocating this device will move it back into the enrollment group
        </span>

      </b-form-checkbox>
    </section>

    <section class="d-inline-flex full-width mt-2">
      <div class="ui-spacer"></div>
      <b-button class="mr-2" @click="$emit('close', true)">Cancel</b-button>
      <b-button :disabled="accepted === 'not_accepted'" variant="danger" @click="$emit('ok', true)">Deallocate Device</b-button>
    </section>
  </section>

</template>
<!--eslint-enable-->

<script>

  export default {
    components: {},
    data() {
      return {
        accepted: 'not_accepted',
      };
    },
  };
</script>
