<style lang="scss">

</style>
<!--eslint-disable-->
<template>
  <section class="text-center">
    <h3>{{title}}</h3>

    <p>Please make sure you have the device in your hands before proceeding</p>

    <section class="my-2">
      <b-form-checkbox
        id="checkbox-1"
        v-model="accepted"
        name="checkbox-1"
        value="accepted"
        unchecked-value="not_accepted"
      >
        <span style="text-align: left">
          I understand that enabling this device will move it back into the enrollment group and I confirm that I have the device in my hands
        </span>

      </b-form-checkbox>
    </section>
    <section class="d-inline-flex full-width mt-2">
      <div class="ui-spacer"></div>
      <b-button class="mr-2" @click="$emit('close', true)">Cancel</b-button>
      <b-button :disabled="accepted === 'not_accepted'" variant="danger" @click="$emit('ok', true)">Enable Device</b-button>
    </section>
  </section>
</template>
<!--eslint-enable-->

<script>

export default {
  components: {},
  props: {
    title: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      accepted: 'not_accepted',
    };
  },
};
</script>
