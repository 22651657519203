<style lang="scss">

</style>
<!--eslint-disable-->
<template>
  <section class="text-center" v-if="loaded">
    <h3>Assign a Device</h3>
    <h4 class="font-weight-bolder my-2">Assign a device to an owner</h4>



    <section class="my-2">

      <b-alert v-if="linkError" show variant="danger">
        <div class="alert-body">
          {{ linkError }}
        </div>
      </b-alert>

      <label>Start Typing to search for Owner</label>
      <CAutoComplete
        :items="owners"
        v-model="owner"
        :get-label="getOwnerLabel"
        :min-len="1"
        :component-item='template'
        :auto-select-one-item="false"
        @update-items="fetchOwners"
        :input-attrs='{class:"form-control"}'
      />
    </section>

    <section class="d-inline-flex full-width mt-2">
      <div class="ui-spacer"></div>
      <b-button class="mr-2" @click="$emit('close', true)">Cancel</b-button>
      <b-button :disabled="owner == null" variant="success" @click="linkDevice()">Attach</b-button>
    </section>
  </section>
</template>
<!--eslint-enable-->

<script>
  import OwnersService from '@/services/OwnersService';
  import deviceService from "@/services/DeviceService";
  import CAutoComplete from "@/components/input/CAutocomplete.vue";
  import OwnerTemplate from "@/components/input/CAutoCompleteTemplates/OwnerTemplate.vue";

  export default {
    components: {
      CAutoComplete
    },
    props: {
      deviceUid: {
        required: true,
        type: String,
      },
    },
    data() {
      return {
        owners: [],
        owner: null,
        template: OwnerTemplate,
        filters: {
          page: 0,
          page_size: 25,
          search: '',
        },
        loaded: false, // used to change color variant as bootstrap does not update after mount
        linkError: "",
      };
    },
    mounted() {
      this.getOwners()
    },
    methods: {
      getOwners() {
        OwnersService.getOwnersAll().then(() => {
          // Set the totp and the refresh timer
         // this.owners = response.data
          this.loaded = true
        }).catch(err => {
          const res = err.response

          // Default error messages
          let errorText = 'Could not fetch the list of owners'

          if (res && res.data.error) {
            errorText = res.data.error
          }
          this.$toast.error(errorText, {
            toastClassName: ['toast-std', 'warning-toast'],
          })
        })
      },
      getOwnerLabel (owner) {
        if (owner === undefined) {
          return ""
        }
        return owner.identifier
      },
      fetchOwners (text) {
        this.filters.search = text
        OwnersService.getOwnersAll(this.filters).then(response => {
          this.owners = response.data.data
          this.loaded = true
        }).catch(err => {
          const res = err.response

          // Default error messages
          let errorText = 'Could not fetch the list of owners'

          if (res && res.data.error) {
            errorText = res.data.error
          }
          this.$toast.error(errorText, {
            toastClassName: ['toast-std', 'warning-toast'],
          })
        })
      },
      async linkDevice() {

        if (this.owner === null) {
          this.linkError = "No Owner selected. Please refresh the page and try again"
          return
        }

        try {
          await deviceService.attachOwner(this.deviceUid, this.owner.uid)

          this.$toast.error("The device has been attached", {
            toastClassName: ['toast-std', 'success-toast'],
          })

          this.$emit('refresh', true)

        } catch (err) {
          const res = err.response

          // Default error messages
          let errorText = 'Could not fetch the list of owners'

          if (res && res.data.error) {
            errorText = res.data.error
          }
          this.linkError = errorText
        }

      }
    },
  };
</script>
<style lang="scss">
.v-autocomplete {
  .v-autocomplete-input-group {
    .v-autocomplete-input {
      font-size: 1.5em;
      padding: 10px 15px;
      box-shadow: none;
      border: 1px solid #157977;
      width: calc(100% - 32px);
      outline: none;
      background-color: #eee;
    }
    &.v-autocomplete-selected {
      .v-autocomplete-input {
        color: #008000;
        background-color: #f2fff2;
      }
    }
  }
  .v-autocomplete-list {
    z-index: 1;
    width: 90%;
    text-align: left;
    border: none;
    border-top: none;
    max-height: 400px;
    overflow-y: auto;
    border-bottom: 1px solid #7367f0;
    position: absolute;
    .v-autocomplete-list-item {
      cursor: pointer;
      background-color: #283046;
      padding: 10px;
      border-bottom: 1px solid #7367f0;
      border-left: 1px solid #7367f0;
      border-right: 1px solid #7367f0;
      &:last-child {
        border-bottom: none;
      }
      &:hover {
        background-color: #283050;
      }
      abbr {
        opacity: 0.8;
        font-size: 0.8em;
        display: block;
        font-family: sans-serif;
      }
    }
  }
}
pre {
  text-align: left;
  white-space: pre-wrap;
  background-color: #eee;
  border: 1px solid #c0c0c0;
  padding: 20px !important;
  border-radius: 10px;
  font-family: monospace !important;
}
.left {
  text-align: left;
}
.note {
  border-left: 5px solid #ccc;
  padding: 10px;
}

</style>
