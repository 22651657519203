<style lang="scss">
.device-stats-area {
  .device-graph-tab { // needed custom tab as bootstrap tab causes graph text to glitch out
    border: 2px solid transparent;
    padding-bottom: 5px;

    &.active {
      border-bottom-color: darkgray;
    }
  }
}
</style>
<!--eslint-disable-->
<template>
  <section class="device-stats-area">
    <section v-if="!loading" class="d-flex flex-wrap">
      <ul class="p-0 mr-2">
        <li class="list-style-none device-graph-tab mb-1 cursor-pointer" :class="{'active': graph.series[0].name === activeGraph}"
            @click="activeGraph = graph.series[0].name" v-for="(graph, index) in temperatureGraphs" :key="index">
          {{ graph.series[0].name }}
        </li>
      </ul>
      <div :title="graph.series[0].name" :active="index === 0" v-for="(graph, index) in temperatureGraphs" :key="index">
        <b-card class="mb-2" v-if="activeGraph === graph.series[0].name">
          <h4 class="col-12 mt-3">{{ graph.series[0].name }} (last {{ filters.page_size }} records)</h4>
          <apexchart width="500" type="bar" :options="graph.options" :series="graph.series"></apexchart>
        </b-card>
      </div>
    </section>
    <section v-else>
      <b-spinner variant="primary"></b-spinner>
    </section>

  </section>

</template>
<!--eslint-enable-->

<script>
import DeviceService from '@/services/DeviceService'

export default {
  components: {},
  props: {
    uid: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      activeGraph: 'Battery Usage',
      filters: {
        page: 0,
        page_size: 10,
      },
      records: [],
      loading: true,
      batteryUsage: {
        options: {
          legend: {
            show: false,
          },
          plotOptions: {
            bar: {
              distributed: true,
            },
          },
          xaxis: {
            title: { text: 'Date/Time Recorded' },
          },
          yaxis: {
            title: { text: 'Battery Percent %' },
          },
        },
        series: [{
          name: 'Battery Usage',
          data: [],
        }],
      },
      temperatureGraphs: [], // has all graphs now
    }
  },
  mounted() {
    this.getHistoryRecords()
  },
  methods: {
    getHistoryRecords() {
      this.loading = true
      DeviceService.getDeviceDataHistoryRecords(this.uid, this.filters)
        .then(res => {
          this.records = res.data.data
          this.filters.page = res.data.meta.page
          this.filters.page_size = res.data.meta.page_size
          this.setBatteryUsageStats(this.records)
          this.setUpTemperatureGraphs(this.records)
        })
        .catch(err => {
          const res = err.response
          let errorText = 'Could not get device records, please refresh and try again'

          if (res && res.data.error) {
            errorText = res.data.error
          }

          this.$toast.error(errorText, {
            toastClassName: ['toast-std', 'warning-toast'],
          })
        })
        .finally(() => {
          this.loading = false
        })
    },
    setUpTemperatureGraphs(records) {
      this.temperatureGraphs = [this.batteryUsage]
      this.temperatureGraphs.push(this.getTemperatureStats(records, 'Battery Temperatures', 'hardware_status_battery_temperatures'))
      this.temperatureGraphs.push(this.getTemperatureStats(records, 'CPU Temperatures', 'hardware_status_cpu_temperatures'))
      this.temperatureGraphs.push(this.getTemperatureStats(records, 'GPU Temperatures', 'hardware_status_gpu_temperatures'))
      this.temperatureGraphs.push(this.getTemperatureStats(records, 'Skin Temperatures', 'hardware_status_skin_temperatures'))
    },
    setBatteryUsageStats(records) {
      this.batteryUsage.series.data = [] // reset

      records.forEach(item => {
        this.batteryUsage.series[0].data.push({
          x: this.$options.filters.formatDateTime(item.battery_level_collected),
          y: item.battery_level,
        })
      })
    },
    getTemperatureStats(records, chartName, recordKey) {
      const graph = {
        options: {
          legend: {
            show: false,
          },
          plotOptions: {
            bar: {
              distributed: true,
            },
          },
          xaxis: {
            title: { text: 'Date/Time Recorded' },
          },
          yaxis: {
            title: { text: 'Temperature Degrees C' },
          },
        },
        series: [{
          name: chartName,
          data: [],
        }],
      }
      records.forEach(item => {
        if(item[recordKey] !== "") {
          let temp = JSON.parse(item[recordKey])
          temp = parseFloat(temp)

          graph.series[0].data.push({
            x: this.$options.filters.formatDateTime(item.battery_level_collected),
            y: temp.toFixed(1),
          })
        }
      })
      return graph
    },
  },
}
</script>
