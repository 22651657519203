<style lang="scss">

</style>
<!--eslint-disable-->
<template>
  <section class="text-center">
    <h3>{{title}}</h3>

    <section class="my-1">
      <b-form-textarea :maxlength="maxLength" v-model="notes"  rows="3" max-rows="6" placeholder="Notes"/>
      <small class="float-right">{{ countCharacters }}</small>
    </section>

    <section class="d-inline-flex full-width mt-2">
      <div class="ui-spacer"></div>
      <b-button class="mr-2" @click="$emit('close', true)">Cancel</b-button>
      <b-button :variant="okVariant" @click="$emit('ok', notes)">Disable</b-button>
    </section>
  </section>
</template>
<!--eslint-enable-->

<script>

export default {
  components: {},
  props: {
    title: {
      required: true,
      type: String,
    },
    okVariant: {
      required: false,
      type: String,
      default: 'danger',
    },
  },
  data() {
    return {
      notes: "",
      maxLength: 200
    };
  },
  computed: {
    countCharacters() {
      let remaining = this.maxLength - this.notes.length
      return remaining === 1 ? "1 character remaining" : remaining + " characters remaining"
    },
  },
  mounted() {
  },
  methods: {
  },
};
</script>
